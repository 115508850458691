
    .harea-form-title {
    font-weight: 700
}
    .harea-form-description {
    --tw-text-opacity: 1;
    color: rgb(200 0 101 / var(--tw-text-opacity))
}
    .harea-form-title, .harea-form-description {
    text-align: center
}
    @media not all and (min-width: 640px) {
    .max-sm\:logo-sm {
        left: 0px;
        right: 0px;
        margin: auto
    }
}