
    .sidebar-left {

    --tw-bg-opacity: 1;

    background-color: rgb(217 217 217 / var(--tw-bg-opacity));

    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

    .sidebar-left .menu-item {

    --tw-text-opacity: 1;

    color: rgb(85 85 85 / var(--tw-text-opacity))
}

    .sidebar-left .menu-item i {

    --tw-text-opacity: 1;

    color: rgb(85 85 85 / var(--tw-text-opacity))
}

    .sidebar-left .menu-item-category > a {

    margin-top: 1rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}

    .sidebar-left .menu-item-category > a:hover,
    .sidebar-left .menu-item-category > a:active {

    background-image: none
}

    .sidebar-left .menu-item .username-menu a {

    font-size: 0.75rem;

    line-height: 1rem
}
    .sidebar-left .menu-item .control-chip {

    --tw-bg-opacity: 1;

    background-color: rgb(1 119 178 / var(--tw-bg-opacity))
}
    
    .parent-layout .main-content {

    margin: auto;

    display: flex;

    width: 100%;

    max-width: 42rem;

    flex: 1 1 0%;

    flex-direction: column;

    gap: 1.25rem;

    padding-top: 0.25rem;

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    padding-bottom: 10rem !important
}
    
    @media (min-width: 640px) {

    .parent-layout .main-content {

        padding-top: 2rem;

        padding-bottom: 2rem
    }
}
