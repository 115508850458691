
    .connect-with{
        border-top: 1px solid #7A7373;
        text-align: center;
        line-height: 0;
        color: #837E7E;
    }
    .connect-with span{
        font-size: 14px;
    }
    .check-box > div >div{
        align-items: center;
    }
    .plo #userName, .plo .relative #password, .plo .relative #new-password, .plo .relative #confirm-password, .plo #email{
        border-radius: 9999px;
        border-width: 2px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
}
    .plo .relative .absolute .control-btn i{
        font-size: 20px;
        padding-right: 15px;
    }
    .condition-general,.politique-confidentialite{
        background: linear-gradient(90.82deg, rgba(217, 217, 217, 0.2343) 0.74%, rgba(173, 173, 173, 0.217731) 50.23%, rgba(242, 240, 240, 0.2343) 99.72%);
    }