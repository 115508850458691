/* temp_stylePlugin:src/views/calendar/css/full-calendar-section.css */
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 50px;
}
.fc .fc-col-header-cell-cushion {
  font-weight: normal;
  font-size: 18px;
  padding: 10px 0px;
}
.fc-v-event {
  background-color: unset;
  border: unset;
}
.no-overflow {
  overflow: visible !important;
}

/* temp_stylePlugin:src/css/vacation.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.dialog-body.animated.relative.w-full.max-w-lg.animated__fadeInUp {
  border-radius: 0.375rem;
}
.dialog-body.animated.menuAppointment.w-full.max-w-lg.animated__fadeInUp {
  border-radius: 0.375rem;
}
.menuAppointment {
  position: relative;
  padding: 0px;
  --tw-text-opacity: 1;
  color: rgb(179 146 249 / var(--tw-text-opacity));
}
.dialog-body.animated.relative.menuAppointment.max-w-lg.animated__fadeInUp::after {
  border-radius: 0.375rem;
}
.menuAppointment::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(233 229 229 / var(--tw-bg-opacity));
  content: "";
  height: 3px;
}
.menuAppointment .menu-item {
  z-index: 1;
  border-bottom-width: 3px;
  --tw-border-opacity: 1;
  border-color: rgb(233 229 229 / var(--tw-border-opacity));
}
.menuAppointment .menu-item.active {
  --tw-border-opacity: 1;
  border-color: rgb(179 146 249 / var(--tw-border-opacity));
}
.menuAppointment .menu-item a:hover,
.menuAppointment .menu-item a:active,
.menuAppointment .menu-item.active a {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.menuAppointment .menu-item .item-badge {
  background-color: rgb(18 14 228 / .12);
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.menuAppointment .menu-item a {
  gap: 0.5rem;
}
.menuAppointment-overflow {
  overflow-x: scroll;
}
.menuAppointment-overflow::-webkit-scrollbar {
  background-color: transparent;
}

/*# sourceMappingURL=../../modules/project/dist/index.css.map */